<template>
    <div class="dialog-card show" :class="{'show': show}" v-show="show">
        <div class="content-body">
            <star-rate :accuracy="percent"></star-rate>
            <div class="content-wrap">
                <div class="title-wrap">
                    <div class="volume">{{ subjectName }}</div>
                    <div class="catalog">{{ getUnitName.toUpperCase() }}</div>
                </div>
                <div class="time-card" v-if="totalWord">
                    <span>总单词：{{ totalWord }}</span>
                    <span style="margin-left: 20px;">正确：{{ correctWord }}</span>
                </div>
                <div class="btn-wrap">
                    <button class="btn again" @click="doAgain">重新挑战</button>
                    <button v-if="!lastCatalog" class="btn continue" @click="doContinue">继续挑战</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import starRate from '@/components/star-rate.vue';

export default {
    props: {
        show: Boolean,
        time: String,
        totalWord: Number,
        correctWord: Number,
        lastCatalog: Boolean
    },
    components: {
        starRate
    },
    computed: {
        percent () {
            return Math.min((this.correctWord/this.totalWord)*100, 100)
        },
        // 课程名称
        subjectName () {
            return this.$store.getters.getSubjectName(this.$route.params.volumeId);
        },
        // 单元名称
        getUnitName () {
            return this.$store.getters.getUnitName(this.$route.params.volumeId, this.$route.params.catalogId);
        },
    },
    methods: {
        // 再次挑战
        doAgain () {
            this.$emit('update:show', false);
            this.$emit('again');
        },

        // 继续挑战
        doContinue () {
            this.$emit('update:show', false);
            this.$emit('continue');
        }
    },
}
</script>

<style lang="scss" scoped>
.dialog-card {
    .star-rate {
        margin: var(--rap-star-rate-m);
    }
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    opacity: 0;
    background: rgba($color: #1F254D, $alpha: .5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    &.show {
        animation: fade .5s ease-in-out forwards;
    }

    .content-body {
        width: 100%;
        height: 100%;
        min-height: 200px;
        max-height: 80vh;
        margin: auto;
        overflow: hidden;
        transform: translateY(50px);
        animation: translateFade .5s ease-in-out forwards;

        .lottie {
            width: 300px;
            margin: 0 auto;
        }

        .content-wrap {
            
            .title-wrap {
                padding: 20px 0;
                font-size: var(--rap-title-wrap-fs);
                color: rgba(254, 198, 64, 1);
                font-weight: bold;
                text-align: center;
                .catalog {
                    font-size: var(--rap-title-catalog-fs);
                }
            }

            .time-card {
                text-align: center;
                padding: 10px 0;
                color: #3B2667;
                font-weight: bold;
                font-size: 24px;
            }
            .btn-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 0;
                .btn {
                    margin: 0 10px;
                    height: 50px;
                    padding: 0 20px;
                    border-radius: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    border: none;
                    color: white;
                    transition: all .2s;
                    
                    &.again {
                        background: darkcyan;
                    }

                    &.continue {
                        background: darkgoldenrod;
                    }

                    &:active {
                        box-shadow: 0 2px 12px 0 rgba($color: #000000, $alpha: .2);
                        opacity: 0.6;
                        transition: all .3s;
                    }
                }
            }
        }
    }
}

@keyframes translateFade {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(-50px);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
    /* 适用于手机设备的样式 */
    /* 根据设备宽度、高度、像素密度等进行区分 */
    @media (max-width: 760px) {
        /deep/ .star-card {
            width: 45px !important;
        }
    }
}

/* 横屏样式 */
@media screen and (orientation: landscape) {
    /* 适用于手机设备的样式 */
    /* 根据设备宽度、高度、像素密度等进行区分 */
    @media screen and (max-width: 900px) {
        /deep/ .content-body {
            height: auto !important;
            min-height: auto !important;
            max-height: none !important;
        }

        /deep/ .star-card {
            width: 55px !important;
        }
    }
}
</style>