function shuffleArray (array) {
    // 使用 Fisher-Yates 算法打乱数组顺序
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function shuffleWord (word) {
    // 将单词拆分成字母数组
    const letters = word.split('').filter(word => word !== ' ');

    // 添加两个随机字母
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const randomLetters = Array.from({ length: 2 }, () => {
        let randomLetter;
        do {
            randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
        } while (letters.includes(randomLetter)); // 确保字母不重复
        return randomLetter;
    });

    // 打乱字母顺序
    const shuffledLetters = shuffleArray([...letters, ...randomLetters]);

    // 返回打乱后的字母数组
    return shuffledLetters;
}