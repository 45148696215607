<template>
    <div class="container">
        <back-btn></back-btn>

        <page-title-card></page-title-card>

        <div class="word-statistics">
            <div class="total-word">总单词：{{ `${whichCount}/${wordList.length}` }}</div>
            <div class="correct-word">正确：{{ correctCount }}</div>
        </div>

        <div class="main-stay" v-if="wordArr.length">
            <template>
                <div class="progress-timer">
                    <circle-progress
                        v-if="resultType == 'progress'"
                        :width="110"    
                        :radius="10"
                        :progress="progressValue"
                        :delay="delay"
                        :duration="26000"
                        barColor="#45b97c"
                        backgroundColor="#DCDFE6"
                        :isRound="true"
                        :isAnimation="true"
                        :title="timer"
                        titleSize="40px"
                        titleColor="#45b97c"
                        ref="progressChild">
                    </circle-progress>

                    <img v-else-if="resultType == 'success'" class="face-appear" style="width: 119px;" src="@/assets/face-success.svg" alt="">
                    <img v-else class="face-appear" style="width: 119px;" src="@/assets/face-fail.svg" alt="">
                </div>

                <div class="word-input">
                    <template v-for="(item, index) in ruleForm.words">
                        <div class="item-word" :style="`color: ${item.color};`" :key="index">{{ item.word || '' }}</div>
                    </template>
                </div>

                <div class="word-operate">
                    <div class="operate-img">
                        <div id="speckId" @click="startSpeak">
                            <play-sound :play="isSpeaking" :size="40" ></play-sound>
                        </div>
                    </div>
                    <div class="operate-btns">
                        <span class="btn delete" @click="deleteWord">删除</span>
                        <span class="btn pass" @click="passWord" v-if="wordArr.length > 1">跳过</span>
                    </div>
                </div>

                <div class="word-btn">
                    <template v-for="item in singleWord">
                        <span class="single-word" :key="item" @click="selectWord(item)">{{ item.toUpperCase() }}</span>
                    </template>
                </div>
            </template>
        </div>

        <template v-else>
            <!-- 成功 -->
            <success-dialog 
                :totalWord="wordList.length"
                :correctWord="correctCount"
                :show.sync="showSuccessDialog"
                :lastCatalog="lastCatalog"
                @again="againGame"
                @continue="continueGame">
            </success-dialog>
        </template>
    </div>
</template>

<script>
import { shuffleWord } from "@/util/util.js";
import CircleProgress from '@/components/circle-progress.vue'
import SuccessDialog from "./success-dialog.vue";

export default {
    components: {
        CircleProgress,
        SuccessDialog
    },
    data() {
        return {
            word: '',
            singleWord: [],
            ruleForm: {
                words: []
            },
            currentIndex: 0,
            voices: [],
            speakCount: 0,
            wordArr: [],
            delay: 0,
            timer: 26,
            progressValue: 100,
            intervalId: null,
            resultType: 'progress',
            isSpeaking: true,
            timeoutId: null,
            passTimeout: null,
            correctCount: 0,
            whichCount: 1,
            showSuccessDialog: true,
        }
    },

    beforeDestroy() {
        this.intervalId && clearInterval(this.intervalId);
        this.stopWord();
    },

    watch: {
        wordList: {
            handler (val) {
                val.length && this.initData();
            },
            immediate: true
        }
    },

    computed: {
        // 路由参数
        routeParams () {
            return this.$route.params;
        },

        // 获取单元列表
        unitList () {
            return this.$store.getters.getUnitList(this.routeParams.volumeId) || [];
        },

        // 单元词汇量
        wordList () {
            return this.$store.getters.getWordList(this.routeParams.volumeId, this.routeParams.catalogId);
        },

        // 是否本册最后一单元
        lastCatalog () {
            const index = (this.unitList || []).findIndex(p => p.id == this.routeParams.catalogId);
            return index == this.unitList.length;
        },

        // 本册下一单元
        nextCatalog () {
            if (!this.lastCatalog) {
                const index = (this.unitList || []).findIndex(p => p.id == this.routeParams.catalogId);
                return this.unitList[index+1];
            }
            return null;
        }
    },

    // mounted () {
    //     window.onload = () => {
    //         setTimeout(() => {
    //             this.delay = 1000000000; 
    //             this.progressValue = 0;
    //             this.stopWord();
    //         }, 100)
    //     }
    // },

    methods: {
        // 开始朗读
        startSpeak() {
            this.delay = 0;
            this.progressValue = 100;
            this.speakCount = 0;
            this.isSpeaking = true;
            this.speakWord();
        },

        // 停止朗读
        stopWord() {
            this.isSpeaking = false;
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            // window.speechSynthesis.cancel();
            this.$speck.cancelTTS();
        },

        // 朗读
        speakWord() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }

            if (!this.isSpeaking) {
                return;
            };

            this.$speck.initTTS({
                text: this.word,
                wordId: this.wordId,
                onend: () => {
                    this.speakCount++;
                    if (this.speakCount >= 3) {
                        this.stopWord();
                        return;
                    };

                    this.timeoutId = setTimeout(() => {
                        this.speakWord();
                    }, 2500);
                }
            });
        },

        // 选中单词
        selectWord(word) {
            if (this.ruleForm.words.every(p => p.word)) return;

            this.ruleForm.words[this.currentIndex].word = word; 
            this.currentIndex++;

            let value = '';
            for (let item of this.ruleForm.words) {
                value += String(item.word);
            };

            if (value.length == this.word.length) {
                if (value.toLowerCase() === this.word.toLowerCase()) {
                    this.resultType = 'success';
                    this.correctCount++;
                    clearInterval(this.intervalId);
                } else {
                    this.resultType = 'fail';
                    clearInterval(this.intervalId);
                }
                this.errorWord();
            } 
        },

        // 删除单词
        deleteWord() {
            this.currentIndex--;
            if (this.currentIndex < 0) this.currentIndex = 0;
            this.ruleForm.words[this.currentIndex].word = '';
        },

        // 跳过单词
        passWord() {
            if (this.timeoutId) {
                clearTimeout(this.passTimeout);
            }
            this.wordArr = this.wordArr.slice(1);
            if (!this.wordArr.length) {
                this.showSuccessDialog = true;
                // 挑战完成  保存记录
                this.$store.dispatch('saveUnitRecord', {
                    ...this.routeParams,
                    dictation: {
                        percent: parseInt(this.correctCount/this.wordList.length * 100),
                        correctCount: this.correctCount,
                    }
                })
                return;
            };
            this.resultType = null;
            this.whichCount++;
            setTimeout(() => {
                this.processWord();
            }, 0);
        },

        // 处理单词
        processWord() {
            this.word = this.wordArr[0].wordEn.toLowerCase().replace(/\s/g, '');
            this.singleWord = Array.from(new Set(shuffleWord(this.word)));
            this.ruleForm.words = [];
            for (let i = 0; i < this.word.length; i++) {
                this.ruleForm.words.push({ word: '', color: '' });
            };
            this.currentIndex = 0;
            this.progressValue = 100;
            this.timer = 26;
            this.resultType = 'progress';
            this.startSpeak();
            this.setTimer();
        },

        // 错误处理
        errorWord() {
            const word = this.word.split('');
            const form_word = this.ruleForm.words;
            for (let i = 0; i < form_word.length; i++) {
                for (let j = 0; j < word.length; j++) {
                    if (i == j) {
                        if (!form_word[i].word || (form_word[i].word.toLowerCase() != word[j].toLowerCase())) {
                            this.$set(form_word[i], 'word', word[j]);
                            this.$set(form_word[i], 'color', '#F56C6C');
                            this.resultType = 'fail';
                        }
                    }
                };
            }; 
            
            this.stopWord();
            this.passTimeout = setTimeout(() => {
                this.passWord();
            }, 3000);
        },
        
        // 倒计时
        setTimer() {
            clearInterval(this.intervalId);
            this.intervalId = null;

            this.intervalId = setInterval(() => {
                this.timer--;

                if (!this.wordArr.length) return;

                if (this.timer <= 0) {
                    this.errorWord();
                    this.stopWord();
                    clearInterval(this.intervalId);
                    // 倒计时结束时的处理逻辑
                    console.log('倒计时结束');
                }
            }, 1000);
        },

        // 重新挑战
        againGame () {
            this.initData();
        },

        // 继续挑战
        continueGame () {
            this.timerInterval = 0;
            if (this.nextCatalog) {
                this.$router.replace({
                    path: '/dictation/' + this.routeParams.volumeId + '/' + this.nextCatalog.id
                })
            }
        },

        // 初始化数据
        initData() {
            Object.assign(this.$data, this.$options.data());
            this.wordArr = [ ...this.wordList ];
            this.processWord();
        }
    }
}
</script>

<style lang="scss" scoped>
    .container {
        padding: 20px 0;
        display: block;
        position: relative;

        .word-statistics {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
            gap: 20px;
            margin: var(--rap-word-statistics-m);
            .total-word {
                color: #fab27b;
            }
            .correct-word {
                color: #f36c21;
            }
        }

        .main-stay {
            overflow-y: var(--rap-word-main-stay-scroll);
            height: var(--rap-word-main-stay-h);
        }

        .progress-timer {
            display: flex;
            justify-content: center;
            margin-bottom: var(--rap-progress-timer-mb);
            height: 130px;
        }
        
        .word-input {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0 20px;
            .item-word {
                color: #fcaf17;
                font-size: 25px;
                border-bottom: 2px solid #fcaf17;
                width: 40px;
                text-align: center;
                min-height: 40px;
            }
        }

        .word-operate {
            display: var(--rap-word-operate-display);
            margin: var(--rap-word-operate-m);
            width: 100%;
            .operate-img {
                height: 40px;
                width: var(--rap-word-img-width);
                display: flex;
                justify-content: var(--rap-word-img-justify-content);
            }
            .operate-btns {
                height: 30px;
                line-height: 30px;
                margin-left: var(--rap-word-operate-ml);
                text-align: var(--rap-word-operate-text-align);
                margin-top: var(--rap-word-btns-mt);
                .btn {
                    border-radius: 50% 30% 30% 60% / 60% 30% 60% 40%;
                    border: 2px solid #fff;
                    padding: var(--rap-word-operate-p);
                    color: #fff;
                    font-size: 14px;
                    font-weight: bold;
                    margin-right: 20px;
                    &.delete {
                        background: rgba($color: #F56C6C, $alpha: 0.8);
                    }
                    &.pass {
                        background: rgba($color: #fcaf17, $alpha: 0.8);
                    }
                }
            }
        }
        
        .word-btn {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0 20px;
            .single-word {
                color: #fff;
                background: rgba($color: #f3715c, $alpha: 0.8);
                border: 2px solid #fff;
                border-radius: 5px;
                font-size: var(--rap-word-btn-fs);
                width: var(--rap-word-btn-w);
                height: var(--rap-word-btn-h);
                line-height: var(--rap-word-btn-lh);
                text-align: center;
            }
        }
        
        .over {
            text-align: center;
            color: #FFC83D;
        }

        .face-appear {
            animation: shake 0.4s linear 1;

            @keyframes shake {
                0% { transform: translateX(0); }
                10%, 90% { transform: translateX(-2px); }
                20%, 80% { transform: translateX(2px); }
                30%, 70% { transform: translateX(-1px); }
                40%, 60% { transform: translateX(1px); }
                50% { transform: translateX(0); }
            }
        }
    }

    /* 竖屏样式 */
    @media screen and (orientation: portrait) {
        /* 适用于手机设备的样式 */
        /* 根据设备宽度、高度、像素密度等进行区分 */
        @media (max-width: 760px) {
            /deep/ .page-title-card, /deep/ .page-title-card .title {
                font-size: 20px !important;
            }
        }
    }
</style>